<template>
  <div v-if="$store.state.message" :class="{'el-drawer__open': $store.state.message}">
    <div class="dialog" @click="close"></div>
    <div class="dialog-win el-drawer rtl">
      <div class="login-title">
        <div>Notifications</div>
        <div class="dialog-close" @click="close">
          <svg-icon icon-class="close" :width="px2rem('20px')" :height="px2rem('20px')" color="#fff"></svg-icon>
        </div>
      </div>
      <div class="messbox">
        <div class="mass-box-top">
          <div v-if="!selectVisible" class="space-between align-center">
            <el-select 
              v-model="tabName" 
              :style="{width:px2rem(tabName=='all'?'157px':tabName=='unread'?'190px':'174px')}"
              popper-class="message-select-down"
              @change="onTabCange"
              >
              <el-option
                v-for="item in tabs"
                :key="item.value"
                :label="item.value"
                :value="item.id">
              </el-option>
            </el-select>
             <el-button v-if="msg.length" class="btn_default btn_select" @click="selectVisible=true">Select</el-button>
          </div>
          <div v-else class="setsDiv">
            <div class="left">
              <el-checkbox :value="allChecked" :indeterminate="isIndeterminate" :disabled="!msg.length" @change="changeCheckBox"></el-checkbox>
            </div>
            <div class="right">
               <el-dropdown trigger="click" 
                  @visible-change="dropShow=!dropShow" 
                  @command="(val)=>setMsg(val,'read')"
                  :disabled="disabledBtns"
                  placement="bottom-start"
                  :class="disabledBtns?'set-as-dropdown-diabled':'set-as-dropdown'"
                >
                <div class="set-as cursor-hand">
                  <div class="aside-card-user">Set as</div>
                  <i v-if="!dropShow" class="el-icon-arrow-down el-icon--right"></i>
                  <i v-else class="el-icon-arrow-up el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">Read</el-dropdown-item>
                  <el-dropdown-item command="0">UnRead</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button class="btn_primary delete" :style="disabledBtns ? {color: '#B8B8B8'} : {}" @click="deleteMsg('all')" :disabled="disabledBtns">Delete</el-button>
              <el-button class="btn_default cancel" @click="cancelMultiple">Cancel</el-button>
            </div>
          </div>
        </div>
        <div v-if="!msg.length&&!pageLoading" class="messbox messbox-default">
          <svg-icon icon-class="messageDefaultPage" :width="px2rem('240px')" :height="px2rem('146px')"></svg-icon>
          <span class="font16 fw5 c252">No message yet</span>
        </div>
        <ul v-else v-infinite-scroll="changePage" :infinite-scroll-disabled="finished" :infinite-scroll-distance="10" class="messList">
          <li
            v-for="(item,index) in msg"
            :key="'msg-'+item.id"
            :class="item.checked ? 'item activeItem' : 'item'"
            @click="handleCurrentChange(item,index)"
          >
            <div v-show="selectVisible" class="check-all mgr20" @click.stop>
              <el-checkbox
                v-model="item.checked"
                @change="checkChange.call(null,arguments[0],index)"
              ></el-checkbox>
            </div>
            <!-- <div :class="['slabel', item.status == 1 ? 'active' : '']">Quote</div> -->
            <div class="listLog">
              <svg-icon  v-if="item.status == 1" iconClass="msgread" :height="px2rem('44px')" :width="px2rem('44px')"></svg-icon>
               <svg-icon v-else iconClass="msgUnread" :height="px2rem('44px')" :width="px2rem('44px')"></svg-icon>
            </div>
            <div class="msg-content">
              <div class="font14 msg-p">
                <p class="title">{{ item | showContent }}</p>
                <div class="handle" :class="{'handle-active':selectVisible}" @click.stop>
                 <span class="handle-icon" title="Select" @click="checkChange(!item.checked,index)"><svg-icon iconClass="select" :height="px2rem('24px')" :width="px2rem('24px')" :color="item.checked?'#31D0AA':'#252525'"></svg-icon></span>
                   <span class="handle-icon" title="Delete" @click="deleteMsg('only',item)"><svg-icon iconClass="msgdelete" :height="px2rem('24px')" :width="px2rem('24px')" color="#252525"></svg-icon></span>
                </div>
              </div>
              <div class="font12 msg-t">{{ item.create_time|format_time }}</div>
            </div>
          </li>
        </ul>
         <div class="liLoading" v-if="pageLoading">
          <Loading content="" v-show="pageLoading" />
        </div>
      </div>
    </div>
    <!-- 选择是所有数据还是当前页数据 -->
    <el-dialog
      :visible.sync="delVisible"
      :show-close="false"
      modal
      width="30%"
      class="delete-box"
      :center="true"
    >
      <div slot="title" class="title">
        <span class="el-icon-warning"></span>
        <span>Select notification</span>
      </div>
      <div class="delete-items">
        <span>
          Which notification do you want to select?
        </span>
      </div>
      <div slot="footer" class="tr mgr30">
        <el-button class="cancel" @click="allSelect(0)">Current page</el-button>
        <el-button class="move" @click="allSelect(1)">ALL notification</el-button>
      </div>
    </el-dialog>
    <!-- 删除模块提示 -->
    <el-dialog
      :visible.sync="delWrangVisible"
      width="558px"
      :modal-append-to-body="true"
      :append-to-body="true"
      :show-close="false"
      :z-index="9999"
    >
      <div class="delDialogTitle">
        <i class="el-icon-warning warningLog"></i>
        <span>Delete message</span>
      </div>
      <div class="delDialogTxt">Are you sure to delete message? You will not be able to undo this action.</div>
      <div slot="footer" class="select-dialog-footer">
        <el-button class="btn-current" @click="delCancle">Cancel</el-button>
        <el-button class="btn-all" @click="delSure">Delete</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Loading from './Loading.vue';
import { px2rem } from '~/libs/tool'
import SvgIcon from './SvgIcon.vue';
export default {
  name: 'Notifications',
  components: { Loading, SvgIcon },
  data() {
    return {
      tabName: 'all',
      tabs:[
        {
          id:'all',
          value:'All messages'
        },
        {
          id:'unread',
          value:'Unread messages'
        },
        {
          id:'read',
          value:'Read messages'
        }
      ],
      showRightItem: false,
      checkList: [],
      msg: [],
      multipleSelection: [],
      UnreadNum: 0,
      totalNum: 0,
      readNum: 0,
      dataStatus: false,
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0
      },
      msgStatus: -10,
      messcont: null,
      finished: false,
      allChecked: false,
      delVisible: false,
      selectVisible: false, // 全选数据选择弹框
      allcheckType: 0, // 1、全选所有的数据 2、当前页面的数据
      delWrangVisible: false,
      isIndeterminate: false,
      pageLoading: false,
      setMsgType:'',
      dropShow:false
    }
  },
  filters:{
    //解决2023-11-9 18:00:00前数据展示标题，后数据展示内容
    "showContent":(row)=>{
      const d1 = new Date("2023-11-9 18:00:00")
      const d2 = new Date(row.create_time)
      return d1 > d2?row.message_title:row.message_content
    }
  },
  computed: {
    auth() {
      return this.$store.state.auth
    },
    disabledBtns() {
      return this.msg.every(e => !e.checked)
    }
  },
  watch: {
    auth: function(e, i) {
      if (e) {
        this.getMsg()
      } else {
        this.msg = []
        this.tableShow.listCount = 0;
        this.$store.commit('openMessage',false);
      }
    },
    '$store.state.message': function(e) {
      if(e) {
        this.onTabCange('all')
      }
    }
  },
  // async asyncData({ app }) {
  //   const datas = {
  //     size: 10,
  //     page: 1,
  //     status: -10
  //   }
  //   const { data } = await app.$getMsg(datas)
  //   return { msg: data.list }
  // },
  // created() {
  //   this.getMsg()
  // },
  methods: {
    px2rem,
    changePage() {
      if(this.pageLoading) return;
      this.getMsg()
    },
    //取消多选
    cancelMultiple(){
      const vm = this
      vm.selectVisible = false
      vm.allChecked = false
      vm.allcheckType = 0
      vm.isIndeterminate = false
      vm.msg.map(item => {
        item.checked = false
        return item
      })
    },
    changeCheckBox(e) {
      let data;
      let ischeck = this.msg.some(val => val.checked)
      if((e && !ischeck) || !ischeck) {
        this.delVisible = true;
      } else {
        data = this.msg.map(item => {
          let obj = {...item};
          obj.checked = false;
          return obj;
        });
        this.msg = data;
        this.allChecked = false;
        this.isIndeterminate = false;
      }
    },
    onTabCange(tab) {
      this.tabName = tab;
      this.isIndeterminate = false;
      this.allChecked = false;
      if(tab == 'all') {
        this.msgStatus = -10;
      } else if(tab == 'unread') {
        this.msgStatus = 0;
      } else if(tab == 'read') {
        this.msgStatus = 1;
      };
      this.msg = [];
      this.tableShow = {
        currentPage: 1,
        pageSize: 10,
        listCount: 0,
      };
      if(!this.auth || this.pageLoading) {
        return;
      }
      this.getMsg();
    },
    // houerset
    onEnterSet() {
      if(this.disabledBtns) return;
      this.showRightItem = true;
    },
    onLeaveSet() {
      this.showRightItem = false;
    },
    getMsg() {
      this.pageLoading = true;
      const vm = this.tableShow
      const data = {size: vm.pageSize,page: vm.currentPage,status: this.msgStatus};
      this.$getMsg(data)
        .then(res => {
          this.UnreadNum = res.data.unread
          this.totalNum = res.data.count
          this.readNum = res.data.read
          this.msg = this.msg.concat(res.data.list)
          if(this.allcheckType&&this.allChecked) {
            this.msg.forEach(item => {
              item.checked = this.allChecked
            })
          } else if(!this.allcheckType && this.msg.some(e => e.checked)) {
            this.isIndeterminate = true
          }
          this.$store.commit('msg/setUnreadNum', this.UnreadNum)
          this.tableShow.listCount = res.data.count || 0
          if (!res.data.list.length || res.data.list.length < 10) {
            this.finished = true
          } else {
            this.finished = false
            this.tableShow.currentPage++
          }
          this.pageLoading = false;
        })
        .catch(() => {
          this.tableShow.currentPage--
        })
    },
    read(e) {
      if (e.status === 0) {
        const data = { messageIdArray: [e.id], choiceAll: 2, readStatus: 1 }
        this.$setMsg(data).then(res => {
          e.status = 1
          this.readNum += 1
          if (this.UnreadNum >= 0) {
            this.UnreadNum -= 1
          }
          this.$store.commit('msg/setUnreadNum', this.UnreadNum)
        })
      }
    },
    setMsg(readStatus) {
      const vm = this
      let choiceAll = vm.allcheckType ? 1 : 2 // 1 =>全选 2=>不全选
      const checkArr = vm.msg.filter(item => item.checked)
      if (!vm.allChecked) {
        // 如果是批量选择
        choiceAll = 2
      }
      if (choiceAll === 2 && !checkArr.length) return
      const messageIdArray = checkArr.map(item => item.id)
      const data = {
        choiceAll,
        messageIdArray,
        readStatus
      }
      vm.showRightItem = false;
      // 设置已读未读
      vm.setRead(data, checkArr, readStatus)
    },
    setRead(data, msgArr, status) {
      const vm = this
      vm.$setMsg(data).then(res => {
        if (res.code === 1) {
          vm.allChecked = false;
          vm.isIndeterminate = false;
          // if (data.choiceAll === 2) {
          //   msgArr.forEach(item => {
          //     item.status = status
          //     item.checked = false
          //   })
          //   return
          // }
          vm.msg = []
          vm.tableShow = {
            currentPage: 1,
            pageSize: 10,
            listCount: 0
          }
          vm.getMsg()
        }
      })
    },
    // 删除按钮
    deleteMsg(type,row) {
      const vm = this
      const allValid = type === 'all' && vm.msg.some(item => item.checked)
      const onlyValid = type === 'only'
      if(onlyValid){
        vm.messcont = row
        vm.allcheckType = 0
      }
      if (!allValid && !onlyValid) return
      vm.delWrangVisible = true
    },
    // 删除警告弹窗
    delCancle() {
      const vm = this
      vm.delWrangVisible = false
      vm.messcont = null
    },
    delSure() {
      const vm = this
      const data = {
        choiceAll:  vm.allcheckType ? 1 : 2, // 1 =>全选 2=>不全选
      }
      if(vm.messcont){
        data.messageIdArray = [vm.messcont.id]
      }else{
        data.messageIdArray = vm.msg.filter(item => item.checked).map(item=>item.id)
      }
      this.$deleteMsg(data).then(res => {
        if (res.code === 1) {
          vm.delWrangVisible = false
          vm.allcheckType = 0
          vm.selectVisible = false
          vm.allChecked = false
          vm.isIndeterminate = false
          if(vm.messcont){
            vm.msg = vm.msg.filter(item => item.id !== vm.messcont.id)
          }else{
            vm.tableShow = {
              currentPage: 1,
              pageSize: 10,
              listCount: 0
            }
            vm.msg = []
            vm.getMsg()
          }
        }
      })
    },
    //全选类型
    allSelect(type) {
      const vm = this
      vm.allcheckType = type
      vm.delVisible = false;
      this.allChecked = true;
      let data = vm.msg.map(item => {
        let obj = {...item}
        obj.checked = vm.allChecked
        return obj
      })
      vm.msg = data;
    },
    // 单选信息
    checkChange(val, index) {
      if(!val) {
        this.allChecked = false;
      }else{
        this.selectVisible = true
      }
      const row = this.msg[index]
      row.checked = val
      this.allcheckType = 0
      this.$set(this.msg, index, row)
      this.allChecked = this.msg.every(item => item.checked);
      let allNotChecked = this.msg.every(item => !item.checked)
      this.isIndeterminate = (allNotChecked || this.allChecked) ? false : true;
    },
    //点击查看信息
    handleCurrentChange(row,index) {
      if(this.selectVisible){
        return this.checkChange(!row.checked,index)
      }
      this.read(row)
      if(row.extra_params?.type==1&&row.extra_params?.no){
        window.open(window.location.origin + `/quote/detail3.0?quote_no=${row.extra_params.no}&type=1`)
      }else if(row.extra_params?.type==2&&row.extra_params?.no){
        window.open(window.location.origin + `/order/detail?id=${row.extra_params.no}`)
      }
    },
    close() {
      this.$store.commit('openMessage', false)
      this.cancelMultiple()
    }
  }
}
</script>
<style scoped lang="scss">
@import './message.scss';
</style>
<style lang="scss">
 .message_content {
    margin-top: 40px;
    font-size: 16px;
    line-height: 22px;
    color: #252525;
    a {
      text-decoration: underline;
    }
  }
  .message-select-down{
    .el-select-dropdown__item{
      padding:0 0 0 18px !important;
      color: #252525;
      font-size: 14px;
      font-weight: 500 !important;
      &.selected{
        color: #252525 !important;
        background-color: #f5f5f5;
      }
      &.selected.hover{
        color: #252525;
        background-color: #f5f5f5;
      }
      &:hover{
        background-color: #fff;
      }
    }
  }
</style>