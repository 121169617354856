const cookieparser = process.server ? require('cookieparser') : undefined
const hasLogin = {}

export const state = () => ({
  avatar: null,
  auth: null,
  dialogLogin: false,
  register: false,
  thankYou: false,
  findPassword: false,
  resetPassword: false,
  message: false,
  tech: '1',
  aside: true,
  resize: 1920,
  scale: 1,
  checkQuoteStatus: 0,
  formData: '',
  loginType: '', //判断是从哪个操作进行登录的
  files: [],
  emailVerify: undefined, // 邮箱跳转是否等待登录验证
  showVerify: false, // 验证邮箱提示
  verifyNumber: 0, // 邮箱验证次数
  countDown: 60, // 验证邮箱倒计时
  reorderList: [], //储存返单的零件列表
  inviterEmail: '', //邀请人邮箱
  inviterCode: '', //邀请code
  showTip: true, //节假日要展示的横幅
  isShowHolidayTip: false,//节假日要展示的交期提示
  indexRouters: ['/', '/a'],
  allMoldsList: [], // 新模具库列表数据
  selectMoldsList: [], // 新模具列表勾选的返单数据
  indexRoute: process.client ? sessionStorage.getItem('indexRoute') : '',
  isGraphicsLibraryLoaded: false,
  isIframeLoaded: false,
  quoteKeyWords:/tolerance|fit|fits|±|um|coating(\s+)thickness|plating(\s+)thickness|fitting|fitted|assembly|assemble|assembled|assembling|gold-plating|platinum-plating/gi //报价备注关键字验证
})

export const mutations = {
  setIndexRoute(state, indexRoute) {
    state.indexRoute = indexRoute
    process.client && sessionStorage.setItem('indexRoute', indexRoute)
  },
  setFiles(state, files) {
    state.files = files
  },
  setReorderList(state, reorderList) {
    state.reorderList = reorderList
  },
  setEmailVerify(state, value) {
    state.emailVerify = value
  },
  setShowVerify(state, value) {
    state.showVerify = value
  },
  setVerifyNumber(state, num) {
    state.verifyNumber = num
  },
  setCountDown(state, value) {
    state.countDown = value
  },
  checkQuote(state, status) {
    state.checkQuoteStatus = Number(status)
  },
  setAuth(state, auth) {
    state.auth = auth
  },
  setAvatar(state, ava) {
    state.avatar = ava
    localStorage.setItem('avatar', ava)
  },
  setFormData(state, data) {
    state.formData = data
  },
  openLogin(state, dialogLogin) {
    state.dialogLogin = dialogLogin
  },
  openRegister(state, register) {
    state.register = register
  },
  setInviterEmail(state, inviterEmail) {
    state.inviterEmail = inviterEmail
  },
  setInviterCode(state, inviterCode) {
    state.inviterCode = inviterCode
  },
  openThankYou(state, thankYou) {
    state.thankYou = thankYou
  },
  openFindPassword(state, findPassword) {
    state.findPassword = findPassword
  },
  openResetPassword(state, resetPassword) {
    state.resetPassword = resetPassword
  },
  openMessage(state, message) {
    state.message = message
  },
  loginOk(state, login) {
    hasLogin.login = login
  },
  setLoginType(state, type) {
    state.loginType = type
  },
  setTech(state, tech) {
    state.tech = tech
  },
  setAside(state, aside) {
    state.aside = aside
  },
  setResize(state, resize) {
    state.resize = resize
  },
  setScale(state, scale) {
    state.scale = scale
  },
  setShowTip(state, val) {
    //节假日要展示的横幅
    state.showTip = val
  },
  setShowHolidayTip(state, val) {
    //节假日要展示的横幅
    state.isShowHolidayTip = val
  },
  // 模具库选择的模具返单数据
  setSelectMoldsList(state, val) {
    state.selectMoldsList = val
  },
  // 模具库列表所有的数据
  setAllMoldsList(state, val) {
    state.allMoldsList = val
    localStorage.setItem('allMoldsList', JSON.stringify(val))
  },
  setGraphicsLibraryLoaded(state, value) {
    state.isGraphicsLibraryLoaded = value;
  },
  setIframeLoaded(state, value) {
    state.isIframeLoaded = value;
  },
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    let auth = null
    let checkQuoteStatus = null
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        auth = parsed.auth
        checkQuoteStatus = parsed.checkQuote
      } catch (err) {
        // 找不到有效的Cookie
      }
    }
    commit('setAuth', auth)
    commit('checkQuote', checkQuoteStatus)
  },
  wantLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      // 打开登录弹窗
      commit(`${data.loginType}`, data.visible)
      Object.defineProperty(hasLogin, 'login', {
        configurable: true,
        get: function() {
          return this.login
        },
        set: function(value) {
          resolve(value) // 发射回调
        }
      })
    })
  },
  updateVerify({ commit }) {
    let mTime = localStorage.getItem('emailCountDown')
    if (!mTime) {
      localStorage.setItem('emailCountDown', +new Date())
      commit('setCountDown', 59)
    } else {
      let time = (+new Date() - mTime) / 1000
      let result = this.state.countDown - parseInt(time)
      if (result > 0) {
        commit('setCountDown', result)
      }
    }
    let timer = setInterval(() => {
      let t = Number(this.state.countDown)
      if (t === 1) {
        commit('setVerifyNumber', 1)
        commit('setCountDown', 60)
        localStorage.removeItem('emailCountDown')
        clearInterval(timer)
      } else {
        commit('setCountDown', this.state.countDown - 1)
      }
    }, 1000)
  }
}
