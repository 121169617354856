import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e317cc8 = () => interopDefault(import('..\\pages\\a.vue' /* webpackChunkName: "pages/a" */))
const _bd087a74 = () => interopDefault(import('..\\pages\\connorsTeam\\index.vue' /* webpackChunkName: "pages/connorsTeam/index" */))
const _00176fe5 = () => interopDefault(import('..\\pages\\mailVerify.vue' /* webpackChunkName: "pages/mailVerify" */))
const _5e3f092e = () => interopDefault(import('..\\pages\\molds\\index.vue' /* webpackChunkName: "pages/molds/index" */))
const _6eb9f5f4 = () => interopDefault(import('..\\pages\\msg\\index.vue' /* webpackChunkName: "pages/msg/index" */))
const _06d817d9 = () => interopDefault(import('..\\pages\\order\\index.vue' /* webpackChunkName: "pages/order/index" */))
const _21bdb7eb = () => interopDefault(import('..\\pages\\quote\\index.vue' /* webpackChunkName: "pages/quote/index" */))
const _45754027 = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _79eda2e0 = () => interopDefault(import('..\\pages\\user.vue' /* webpackChunkName: "pages/user" */))
const _1a78ad0a = () => interopDefault(import('..\\pages\\user\\password.vue' /* webpackChunkName: "pages/user/password" */))
const _abb26110 = () => interopDefault(import('..\\pages\\connorsTeam\\newQuote.vue' /* webpackChunkName: "pages/connorsTeam/newQuote" */))
const _bb3f74ba = () => interopDefault(import('..\\pages\\help-center\\specifications-policy.vue' /* webpackChunkName: "pages/help-center/specifications-policy" */))
const _783f436b = () => interopDefault(import('..\\pages\\member\\agreement.vue' /* webpackChunkName: "pages/member/agreement" */))
const _32ea4fa3 = () => interopDefault(import('..\\pages\\member\\findPassword.vue' /* webpackChunkName: "pages/member/findPassword" */))
const _1cfa84d6 = () => interopDefault(import('..\\pages\\member\\joinTeam.vue' /* webpackChunkName: "pages/member/joinTeam" */))
const _082b65ca = () => interopDefault(import('..\\pages\\member\\login.vue' /* webpackChunkName: "pages/member/login" */))
const _15caf7ae = () => interopDefault(import('..\\pages\\member\\privacy.vue' /* webpackChunkName: "pages/member/privacy" */))
const _708b66d2 = () => interopDefault(import('..\\pages\\member\\register.vue' /* webpackChunkName: "pages/member/register" */))
const _08c2e1eb = () => interopDefault(import('..\\pages\\member\\resetPassword.vue' /* webpackChunkName: "pages/member/resetPassword" */))
const _e7c319c6 = () => interopDefault(import('..\\pages\\member\\thank-you.vue' /* webpackChunkName: "pages/member/thank-you" */))
const _6c7df2da = () => interopDefault(import('..\\pages\\order\\detail.vue' /* webpackChunkName: "pages/order/detail" */))
const _d3e5b266 = () => interopDefault(import('..\\pages\\order\\orderSubmit.vue' /* webpackChunkName: "pages/order/orderSubmit" */))
const _0d05cdaa = () => interopDefault(import('..\\pages\\order\\paybank.vue' /* webpackChunkName: "pages/order/paybank" */))
const _4441c74d = () => interopDefault(import('..\\pages\\order\\payment.vue' /* webpackChunkName: "pages/order/payment" */))
const _51d243be = () => interopDefault(import('..\\pages\\order\\paystatus.vue' /* webpackChunkName: "pages/order/paystatus" */))
const _68438798 = () => interopDefault(import('..\\pages\\order\\print.vue' /* webpackChunkName: "pages/order/print" */))
const _77a94057 = () => interopDefault(import('..\\pages\\order\\utils.js' /* webpackChunkName: "pages/order/utils" */))
const _97e3e126 = () => interopDefault(import('..\\pages\\quote\\blank.vue' /* webpackChunkName: "pages/quote/blank" */))
const _79b84886 = () => interopDefault(import('..\\pages\\quote\\checkout.vue' /* webpackChunkName: "pages/quote/checkout" */))
const _a36755f0 = () => interopDefault(import('..\\pages\\quote\\detail.vue' /* webpackChunkName: "pages/quote/detail" */))
const _62cc4646 = () => interopDefault(import('..\\pages\\quote\\detail3.0.vue' /* webpackChunkName: "pages/quote/detail3.0" */))
const _d9682d6a = () => interopDefault(import('..\\pages\\quote\\manualInfo.vue' /* webpackChunkName: "pages/quote/manualInfo" */))
const _029202da = () => interopDefault(import('..\\pages\\quote\\manualNew.vue' /* webpackChunkName: "pages/quote/manualNew" */))
const _a6237b26 = () => interopDefault(import('..\\pages\\quote\\quoteIndex.vue' /* webpackChunkName: "pages/quote/quoteIndex" */))
const _17a5905a = () => interopDefault(import('..\\pages\\quote\\quoteList.vue' /* webpackChunkName: "pages/quote/quoteList" */))
const _bc724b60 = () => interopDefault(import('..\\pages\\quote\\reload.vue' /* webpackChunkName: "pages/quote/reload" */))
const _46c60230 = () => interopDefault(import('..\\pages\\quote\\request.vue' /* webpackChunkName: "pages/quote/request" */))
const _074dc696 = () => interopDefault(import('..\\pages\\quote\\rfq.vue' /* webpackChunkName: "pages/quote/rfq" */))
const _65636a3b = () => interopDefault(import('..\\pages\\quote\\rfq-2.vue' /* webpackChunkName: "pages/quote/rfq-2" */))
const _28fc43c0 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\inviteTeamDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/inviteTeamDialog" */))
const _5ff9dcaa = () => interopDefault(import('..\\pages\\connorsTeam\\components\\orderList.vue' /* webpackChunkName: "pages/connorsTeam/components/orderList" */))
const _fa86f450 = () => interopDefault(import('..\\pages\\connorsTeam\\components\\quoteList.vue' /* webpackChunkName: "pages/connorsTeam/components/quoteList" */))
const _2aeac237 = () => interopDefault(import('..\\pages\\molds\\C\\moldsSkeleton.vue' /* webpackChunkName: "pages/molds/C/moldsSkeleton" */))
const _9670a890 = () => interopDefault(import('..\\pages\\molds\\C\\selectProdMoldsDilog.vue' /* webpackChunkName: "pages/molds/C/selectProdMoldsDilog" */))
const _6c1d02f8 = () => interopDefault(import('..\\pages\\molds\\C\\uploadMolding.vue' /* webpackChunkName: "pages/molds/C/uploadMolding" */))
const _b74b7a78 = () => interopDefault(import('..\\pages\\order\\components\\AddOrderToTeam.vue' /* webpackChunkName: "pages/order/components/AddOrderToTeam" */))
const _3b1b71e2 = () => interopDefault(import('..\\pages\\order\\components\\checkDfm.vue' /* webpackChunkName: "pages/order/components/checkDfm" */))
const _406e8e20 = () => interopDefault(import('..\\pages\\order\\components\\checkModel.vue' /* webpackChunkName: "pages/order/components/checkModel" */))
const _72d629ce = () => interopDefault(import('..\\pages\\order\\components\\confirmAddonPay.vue' /* webpackChunkName: "pages/order/components/confirmAddonPay" */))
const _56e7ba1b = () => interopDefault(import('..\\pages\\order\\components\\confirmBalance.vue' /* webpackChunkName: "pages/order/components/confirmBalance" */))
const _5766a167 = () => interopDefault(import('..\\pages\\order\\components\\confirmPay.vue' /* webpackChunkName: "pages/order/components/confirmPay" */))
const _7fd8ebbb = () => interopDefault(import('..\\pages\\order\\components\\confirmSample.vue' /* webpackChunkName: "pages/order/components/confirmSample" */))
const _205e20f2 = () => interopDefault(import('..\\pages\\order\\components\\LogisticsInfo.vue' /* webpackChunkName: "pages/order/components/LogisticsInfo" */))
const _08734065 = () => interopDefault(import('..\\pages\\order\\components\\orderActive.vue' /* webpackChunkName: "pages/order/components/orderActive" */))
const _7dca78d0 = () => interopDefault(import('..\\pages\\order\\components\\orderSteps.vue' /* webpackChunkName: "pages/order/components/orderSteps" */))
const _548c857c = () => interopDefault(import('..\\pages\\order\\components\\RemoveOrderFromTeam.vue' /* webpackChunkName: "pages/order/components/RemoveOrderFromTeam" */))
const _bd599c32 = () => interopDefault(import('..\\pages\\order\\components\\updatePO.vue' /* webpackChunkName: "pages/order/components/updatePO" */))
const _26a26fd6 = () => interopDefault(import('..\\pages\\order\\components\\view3d.vue' /* webpackChunkName: "pages/order/components/view3d" */))
const _8a3cc456 = () => interopDefault(import('..\\pages\\order\\mixins\\ImporComponents.js' /* webpackChunkName: "pages/order/mixins/ImporComponents" */))
const _375ac255 = () => interopDefault(import('..\\pages\\quote\\quoteIndex' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a",
    component: _2e317cc8,
    name: "a"
  }, {
    path: "/connorsTeam",
    component: _bd087a74,
    name: "connorsTeam"
  }, {
    path: "/mailVerify",
    component: _00176fe5,
    name: "mailVerify"
  }, {
    path: "/molds",
    component: _5e3f092e,
    name: "molds"
  }, {
    path: "/msg",
    component: _6eb9f5f4,
    name: "msg"
  }, {
    path: "/order",
    component: _06d817d9,
    name: "order"
  }, {
    path: "/quote",
    component: _21bdb7eb,
    name: "quote"
  }, {
    path: "/test",
    component: _45754027,
    name: "test"
  }, {
    path: "/user",
    component: _79eda2e0,
    name: "user",
    children: [{
      path: "password",
      component: _1a78ad0a,
      name: "user-password"
    }]
  }, {
    path: "/connorsTeam/newQuote",
    component: _abb26110,
    name: "connorsTeam-newQuote"
  }, {
    path: "/help-center/specifications-policy",
    component: _bb3f74ba,
    name: "help-center-specifications-policy"
  }, {
    path: "/member/agreement",
    component: _783f436b,
    name: "member-agreement"
  }, {
    path: "/member/findPassword",
    component: _32ea4fa3,
    name: "member-findPassword"
  }, {
    path: "/member/joinTeam",
    component: _1cfa84d6,
    name: "member-joinTeam"
  }, {
    path: "/member/login",
    component: _082b65ca,
    name: "member-login"
  }, {
    path: "/member/privacy",
    component: _15caf7ae,
    name: "member-privacy"
  }, {
    path: "/member/register",
    component: _708b66d2,
    name: "member-register"
  }, {
    path: "/member/resetPassword",
    component: _08c2e1eb,
    name: "member-resetPassword"
  }, {
    path: "/member/thank-you",
    component: _e7c319c6,
    name: "member-thank-you"
  }, {
    path: "/order/detail",
    component: _6c7df2da,
    name: "order-detail"
  }, {
    path: "/order/orderSubmit",
    component: _d3e5b266,
    name: "order-orderSubmit"
  }, {
    path: "/order/paybank",
    component: _0d05cdaa,
    name: "order-paybank"
  }, {
    path: "/order/payment",
    component: _4441c74d,
    name: "order-payment"
  }, {
    path: "/order/paystatus",
    component: _51d243be,
    name: "order-paystatus"
  }, {
    path: "/order/print",
    component: _68438798,
    name: "order-print"
  }, {
    path: "/order/utils",
    component: _77a94057,
    name: "order-utils"
  }, {
    path: "/quote/blank",
    component: _97e3e126,
    name: "quote-blank"
  }, {
    path: "/quote/checkout",
    component: _79b84886,
    name: "quote-checkout"
  }, {
    path: "/quote/detail",
    component: _a36755f0,
    name: "quote-detail"
  }, {
    path: "/quote/detail3.0",
    component: _62cc4646,
    name: "quote-detail3.0"
  }, {
    path: "/quote/manualInfo",
    component: _d9682d6a,
    name: "quote-manualInfo"
  }, {
    path: "/quote/manualNew",
    component: _029202da,
    name: "quote-manualNew"
  }, {
    path: "/quote/quoteIndex",
    component: _a6237b26,
    name: "quote-quoteIndex"
  }, {
    path: "/quote/quoteList",
    component: _17a5905a,
    name: "quote-quoteList"
  }, {
    path: "/quote/reload",
    component: _bc724b60,
    name: "quote-reload"
  }, {
    path: "/quote/request",
    component: _46c60230,
    name: "quote-request"
  }, {
    path: "/quote/rfq",
    component: _074dc696,
    name: "quote-rfq"
  }, {
    path: "/quote/rfq-2",
    component: _65636a3b,
    name: "quote-rfq-2"
  }, {
    path: "/connorsTeam/components/inviteTeamDialog",
    component: _28fc43c0,
    name: "connorsTeam-components-inviteTeamDialog"
  }, {
    path: "/connorsTeam/components/orderList",
    component: _5ff9dcaa,
    name: "connorsTeam-components-orderList"
  }, {
    path: "/connorsTeam/components/quoteList",
    component: _fa86f450,
    name: "connorsTeam-components-quoteList"
  }, {
    path: "/molds/C/moldsSkeleton",
    component: _2aeac237,
    name: "molds-C-moldsSkeleton"
  }, {
    path: "/molds/C/selectProdMoldsDilog",
    component: _9670a890,
    name: "molds-C-selectProdMoldsDilog"
  }, {
    path: "/molds/C/uploadMolding",
    component: _6c1d02f8,
    name: "molds-C-uploadMolding"
  }, {
    path: "/order/components/AddOrderToTeam",
    component: _b74b7a78,
    name: "order-components-AddOrderToTeam"
  }, {
    path: "/order/components/checkDfm",
    component: _3b1b71e2,
    name: "order-components-checkDfm"
  }, {
    path: "/order/components/checkModel",
    component: _406e8e20,
    name: "order-components-checkModel"
  }, {
    path: "/order/components/confirmAddonPay",
    component: _72d629ce,
    name: "order-components-confirmAddonPay"
  }, {
    path: "/order/components/confirmBalance",
    component: _56e7ba1b,
    name: "order-components-confirmBalance"
  }, {
    path: "/order/components/confirmPay",
    component: _5766a167,
    name: "order-components-confirmPay"
  }, {
    path: "/order/components/confirmSample",
    component: _7fd8ebbb,
    name: "order-components-confirmSample"
  }, {
    path: "/order/components/LogisticsInfo",
    component: _205e20f2,
    name: "order-components-LogisticsInfo"
  }, {
    path: "/order/components/orderActive",
    component: _08734065,
    name: "order-components-orderActive"
  }, {
    path: "/order/components/orderSteps",
    component: _7dca78d0,
    name: "order-components-orderSteps"
  }, {
    path: "/order/components/RemoveOrderFromTeam",
    component: _548c857c,
    name: "order-components-RemoveOrderFromTeam"
  }, {
    path: "/order/components/updatePO",
    component: _bd599c32,
    name: "order-components-updatePO"
  }, {
    path: "/order/components/view3d",
    component: _26a26fd6,
    name: "order-components-view3d"
  }, {
    path: "/order/mixins/ImporComponents",
    component: _8a3cc456,
    name: "order-mixins-ImporComponents"
  }, {
    path: "/",
    component: _375ac255,
    name: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
