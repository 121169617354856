/**
 * api接口统一管理
 * 将API注入Vue,用于客户端访问
 * 将API注入context,用于服务端访问
 */
export default ({ app }, inject) => {
  /************************************************************************************************
   ******************************************SEO部分**********************************************
   ************************************************************************************************/
  // 为指定页面获取SEO信息
  inject('getSeo', data => app.$axios.get('/5d26a98b01add', { params: data }))
  // 全局seo信息
  inject('getSeoAll', data =>
    app.$axios.get('/5cd4ed24b812e', { params: data })
  )

  /************************************************************************************************
   ******************************************用户部分**********************************************
   ************************************************************************************************/
  //  登录
  inject('login', data => app.$axios.post('/5ccfd6629449a', data))
  //  登出
  inject('logout', data => app.$axios.get('/5ccfed8252a16', { params: data }))
  // 注册
  inject('register', data => app.$axios.post('/5cda73342650d', data))
  // 获取验证码
  inject('getVerify', data => app.$axios.post('/5cd136257a0aa', data))
  // 验证验证码是否正确
  inject('spyVerify', data => app.$axios.post('/5cd936ee3de3d', data))
  // 检测账号是否存在
  inject('detectionUser', data => app.$axios.post('/5ce357e8a7ce9', data))
  // 获取用户信息
  inject('getUser', data => app.$axios.get('/5cda79852ec72', { params: data }))
  // 修改用户信息
  inject('modifytUser', data => app.$axios.post('/5cda7a54a2935', data))
  // 上传头像
  inject('avatar', data => app.$axios.post('/5cda775163e88', data))
  // 修改密码
  inject('modifyPwd', data => app.$axios.post('/5cd1414b4066f', data))
  // 忘记密码
  inject('forgetPwd', data => app.$axios.post('/5cda70c7221d6', data))
  // 用户地址列表
  inject('addressList', data =>
    app.$axios.get('/5cd3924424ee3', { params: data })
  )
  // 世界地区
  inject('countryList', data =>
    app.$axios.get('/5ce535fcc6f0c', { params: data })
  )
  // 省
  inject('stateList', data => app.$axios.post('/5ce536922c182', data))
  // 地区
  inject('areasList', data => app.$axios.post('/5ce547bcbfca5', data))
  // 获取默认地址
  inject('addressDefault', data =>
    app.$axios.get('/5cf4e3e8d1b7d', { params: data })
  )
  // 根据id获取指定地址
  inject('addressId', data => app.$axios.post('/5d53d84208159', data))
  // 添加&修改地址
  inject('areasSave', data => app.$axios.post('/5cd399890d437', data))
  // 删除地址
  inject('areasDel', data => app.$axios.post('/5cd3a0c1d6451', data))
  // 设为默认地址
  inject('areasDefault', data => app.$axios.post('/5cd3a310847e1', data))
  // 获取消息列表
  inject('getMsg', data => app.$axios.post('/5d1af8676f492', data))
  // 设置为已读消息
  inject('setMsg', data => app.$axios.post('/5d1afec40d3f9', data))
  // 删除消息
  inject('deleteMsg', data => app.$axios.post('/5d1b0999cb337', data))
  // 用户协议
  inject('agreement', data =>
    app.$axios.get('/5d3186d96bce6', { params: data })
  )

  /******************************************************************************
   * 在线聊天部分
   */
  // 获取隐私协议状态
  inject('getChatPrivacyStatus', data =>
    app.$axios.get('6414130c7eb72', { params: data })
  )
  // 同意隐私协议
  inject('agreeChatPrivacyStatus', data =>
    app.$axios.post('63f6ce47c1af3', data)
  )
  // 获取聊天房间
  inject('getChatRoom', data => app.$axios.post('63f73fc164983', data))
  // 获取聊天明细
  inject('getChatHistory', data =>
    app.$axios.get('63fc5158f37f5', { params: data })
  )
  // 获取业务员信息
  inject('getAccountInfo', data => app.$axios.get('64000a489b5cd', data))
  // 消息列表
  inject('getChatMsgList', data =>
    app.$axios.get('63f5e0b3445b6', { params: data })
  )
  // 发送聊天记录
  inject('sendChatMessage', data => app.$axios.post('63f85a46583a0', data))
  // 获取未读消息数量
  inject('getUnreadMsg', data => app.$axios.get('63feb3f6a352f', data))
  // 清除未读数量
  inject('clearUnreadMsg', data => app.$axios.post('63f5e0ddc92fa', data))
  // 发送聊天欢迎语和额外参数
  inject('getWelcomeSpeech', data =>
    app.$axios.get('64a616edb9416', { params: data })
  )
  // 推送前台时间到后台
  inject('pushCurrentTime', data => app.$axios.post('661e405b2d7bf', data ))

  /************************************************************************************************
   ******************************************公共部分**********************************************
   ************************************************************************************************/
  //  上传Base64
  inject('uploadBase64', data => app.$axios.post('/5d0c965937b4e', data))
  //  服务与支持
  inject('contact', data => app.$axios.post('/5d64dfd00571b', data))

  /************************************************************************************************
   ******************************************报价部分**********************************************
   ************************************************************************************************/
  //  获取报价单列表
  inject('offerList', data =>
    app.$axios.get('/60475a55c926a', { params: data })
  )
  //  提交报价 - dfm
  inject('Quotation', data => app.$axios.post('/5cde8108a8a3d', data))
  //  提交报价 - 快速
  inject('RapidQuote', data => app.$axios.post('/5e8af37b4aa24', data))
  //  提交报价 2.0

  inject('RapidQuoteSub', data =>
    app.$axios({
      method: 'post',
      url: '/60471c090c497',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      cacheKey: 'RapidQuoteSub',
      cancel: true,
      data
    })
  )
  //  获取零件夹装参数
  inject('GetPartsCosting', data =>
    app.$axios.get('/617255c7b32b5', { params: data })
  )
  //  提交报价 New
  inject('RapidQuoteTow', data => app.$axios.post('/5fa4ca825ec76', data))
  //  获取价格
  inject('GetQuote', data => app.$axios.get('/5ffab7f1c8c9b', { params: data }))
  // 工艺类型(报价)
  inject('getTechnology', data =>
    app.$axios.get('/5cdcd1f6bc637', { params: data })
  )

  // 材料列表(报价)
  inject('getMaterial', data =>
    app.$axios.get('/5cdcd0c2b313d', { params: data })
  )
  // 材料列表(报价)(两列)
  inject('getMaterialTwo', data =>
    app.$axios.get('/5f6eab4f8b934', { params: data })
  )

  // 表面处理列表(报价)
  inject('getSurface', data =>
    app.$axios.get('/5cdcd1a8b4bce', { params: data })
  )

  // 取消报价单
  inject('offerCancle', data => app.$axios.post('/5d05b650982d0', data))
  // 取消报价单2.0
  inject('offerCancleTwo', data => app.$axios.post('/604738d9b7fcd', data))
  // 删除报价单
  inject('offerDel', data => app.$axios.post('/6232dc802b853', data))
  // 请求打印报价单
  inject('printQuiry', data => app.$axios.post('/5d05e06d6804f', data))
  // 请求打印报价单
  inject('getQuiry', data => app.$axios.post('/5d15c4c05b5ea', data))
  // 人工报价
  inject('setManual', data => app.$axios.post('/5d2801227b197', data))
  // 获取报价详情 3.0
  inject('GetOfferDetail', data =>
    app.$axios.get('/6047361aa5aa3', { params: data })
  )
  // 报价单转订单
  inject('setOrder', data => app.$axios.post('/6054659253f00', data))
  // 请求零件表
  inject('getVersion', data =>
    app.$axios.get('/5d648f94cf116', { params: data })
  )
  // 设置零件表数量
  inject('setDefault', data => app.$axios.post('/5e8efdde04209', data))
  // 获取光洁度列表
  inject('getRaList', data =>
    app.$axios.get('/66029fa39da36', { params: data })
  )

  /************************************************************************************************
   ******************************************订单部分**********************************************
   ************************************************************************************************/
  // 订单列表
  inject('orderList', data =>
    app.$axios.get('/5ce51ae389611', { params: data })
  )
  // 取消订单
  inject('orderCancle', data => app.$axios.post('/5ce972dda560a', data))
  // 获取订单详情
  inject('orderDetail', data =>
    app.$axios.get('/5ce65df98dc5d', { params: data })
  )
  // 获取订单生产进度
  inject('productProcess', data => app.$axios.post('/5d2461c05400b', data))
  // 订单支付
  inject('payOrder', data => app.$axios.post('/5ce89e9e81314', data))
  // PAYPAL支付
  inject('payPal', data => app.$axios.get('/5e1d29f17d08a', { params: data }))
  // 获取支付状态
  inject('getpayPal', data =>
    app.$axios.get('/5e4764c04755a', { params: data })
  )
  // 订单详情-优惠券使用
  inject('orderUseDiscount', data => app.$axios.post('/653b7852b7f4b', data))
  // 修改支付状态
  inject('changesPayment', data => app.$axios.post('/60ffb239e2818', data))
  // 修改模具首款状态
  inject('changeFirstMoldPayment', data =>
    app.$axios.post('/646adb4e2839d', data)
  )
  // 修改追加款项支付方式
  inject('changeAddonPayment', data => app.$axios.post('/66b1ef6781d67', data))
  // 请求打印订单
  inject('printOrder', data => app.$axios.post('/5d05e091dc406', data))
  // 获取打印订单地址
  inject('getPrint', data => app.$axios.post('/5d15c49169bd1', data))
  // 物流进度
  inject('logistics', data => app.$axios.post('/5d328d419e85d', data))
  // 确认收货
  inject('receive', data => app.$axios.post('/5d156f97d9150', data))
  // 确认收货评分
  inject('receiveGrade', data => app.$axios.post('/5d69085880caa', data))

  /************************************************************************************************
   *****************************************资讯、博客、帮助中心--内容******************************************
   ************************************************************************************************/
  // 饰面库
  inject('getFinishes', data => app.$axios.post('/5cff588480040', data))
  // 材料库二级分类及详细信息
  inject('materialType', data => app.$axios.post('/5cff56241d6ea', data))
  // 博客分类
  inject('getBloglist', data => app.$axios.post('/5d1c9420b4ff2', data))
  // 博客内容
  inject('getBlog', data => app.$axios.post('/5d02241bb6db5', data))
  // 博客推荐
  inject('getHomeBlog', data =>
    app.$axios.get('/5d1580ab18e68', { params: data })
  )
  // 博客标签列表
  inject('getCommand', data =>
    app.$axios.get('/5d81e6b5423de', { params: data })
  )
  // 帮助中心列表
  inject('helpList', data => app.$axios.get('/5d70709684b86'))
  // 帮助中心文章详情
  inject('helpDetail', data =>
    app.$axios.get('/5d7080db4d5fa', { params: data })
  )

  /************************************************************************************************
   *****************************************上传&下载*********************************************
   ************************************************************************************************/
  // 报价单下载附件
  inject('downloadQuote', data => {
    return `${app.$axios.defaults.baseURL}/5d14654a1b1f9${data}`
  })
  // 付款信息下載
  inject('downloadPayment', data => {
    return `${app.$axios.defaults.baseURL}/5d63485f8fb36${data}`
  })
  // 3D模型上传
  inject('3DModelUploading', data => {
    return `${app.$axios.defaults.baseURL}/5d15ed1791c1c`
  })
  // 3D首次模型上传
  inject('first3DModelUploading', data =>
    app.$axios.post('/5d15ed1791c1c', data)
  )
  // 附件上传
  inject('accessoryUploading', data => {
    return `${app.$axios.defaults.baseURL}/5d15ed1791c1c`
  })
  // 3D模型下載
  inject('3DDownload', data =>
    app.$axios.get('/5ffd76db7be93', { params: data })
  )
  // 根据文件key获取文件信息
  inject('getInfoByFileKey', data =>
    app.$axios.get('/66ea69c115940', { params: data })
  )

  // 获取团队信息
  inject('getTeam', data => app.$axios.get('/5e96d0e93d3b9', { params: data }))
  // 修改团队信息
  inject('editTeam', data => app.$axios.post('/5e96d8f90e74e', data))
  // 获取邀请人信息
  inject('getInviteInfo', data =>
    app.$axios.get('/5ea2a7829a9b5', { params: data })
  )
  // 发送邀请
  inject('invite', data => app.$axios.post('/5ea29eeb98484', data))
  // 获取Quote
  inject('quoteNo', data => app.$axios.get('/603cb63fed575', { params: data }))
  // 获取交期
  inject('getDelivery', data =>
    app.$axios.get('/605afa87cd909', { params: data })
  )
  // 获取运费
  inject('getFreight', data => app.$axios.post('/606eba302a805', data))
  // 报价客户支持
  inject('supportSend', data => app.$axios.post('/606596b56e9b6', data))
  // dfm请求
  inject('getDfm', data => app.$axios.post('/6073a9120da32', data))
  inject('dfnmDetails', data =>
    app.$axios.get('/638eb8729c97c', { params: data })
  )
  inject('confirmDfm', data => app.$axios.post('/638eafe64b77d', data))
  inject('modelDetails', data =>
    app.$axios.get('/6390357572e34', { params: data })
  )
  inject('confirmMold', data => app.$axios.post('/639035b167a07', data))
  inject('sampleDetails', data =>
    app.$axios.get('/6391a3ac1fcb7', { params: data })
  )
  inject('confirmSample', data => app.$axios.post('/639151dabbe9f', data))
  inject('balancePay', data => app.$axios.post('/63967f1b30bdb', data))
  // 更新订单列表new图标
  inject('updateOrderNew', data => app.$axios.post('/63db155d56956', data))
  // 更新(dfm报告和模具设计)update图标状态
  inject('updateStatus', data => app.$axios.post('/63d64d6317a13', data))
  // 下载模具设计稿文件
  inject('downMoldFile', data =>
    app.$axios.get('/63d789c4aaa5b', { params: data, responseType: 'blob' })
  )
  // 订单pi
  inject('getOrderPi', data =>
    app.$axios.get('/605c490551b1b', { params: data })
  )
  // 订单活动记录
  inject('orderActive', data =>
    app.$axios.get('/639997a37b79e', { params: data })
  )

  // 获取mid
  inject('getMid', data => app.$axios.get('/6094a9ea862e6', { params: data }))
  // 获取零件Stl
  inject('getQueryInfo', data =>
    app.$axios.get('/6094ad31cad9e', { params: data })
  )
  // 提交PDF图纸文档给自动报价系统
  inject('subPDF', data => app.$axios.post('/60e663c5d2318', data))
  // 删除PDF图纸文档
  inject('delPDF', data => app.$axios.post('/60e664f217b69', data))
  // checkout 详情
  inject('checkoutDetail', data =>
    app.$axios.get('/60f51e347b35b', { params: data })
  )
  inject('quotePdfData', data =>
    app.$axios.get('/605c491f60bb0', { params: data })
  )
  inject('pdfDownloadRecord', data => app.$axios.post('/6668f381af409', data))
  // 复制报价单获取接口
  inject('getQuote', data => app.$axios.get('/60f91950da73f', { params: data }))
  // 新用户邮箱收集
  inject('setUserMail', data => app.$axios.post('/6100ffbee0c95', data))
  // 根据兑换码获取优惠券详情
  inject('getDiscount', data =>
    app.$axios.get('/61089930c4021', { params: data })
  )
  // 获取运输方式列表
  inject('getTransport', data =>
    app.$axios.get('/610a4d2b94956', { params: data })
  )
  // 获取交期模式费用列表
  inject('getLeadTimeCost', data =>
    app.$axios.get('/60f920baa1d33', { params: data })
  )
  // 修改订单账单地址
  inject('BillingAddress', data => app.$axios.post('/61a743c4c2cb2', data))
  // 查看是否拥有月结功能
  inject('getUserMonthly', data =>
    app.$axios.get('/61bc2d6a3b4e8', { params: data })
  )
  // 批量下载零件
  inject('3DDownloadAll', data =>
    app.$axios.post('/61dc012e93b43', data, { responseType: 'blob' })
  )
  // 获取默认材料表处
  inject('getDefaultParameter', data =>
    app.$axios.get('/6209c4773e3fd', { params: data })
  )
  // 获取用户运输账户信息
  inject('getUserTransport', data =>
    app.$axios.get('/62145ad5a1d33', { params: data })
  )
  // 保存用户运输账号信息
  inject('setUserTransport', data => app.$axios.post('/6221ab9093db2', data))
  // 设置3D零件模型参数
  inject('set3DParameter', data => app.$axios.post('/622878f0726c6', data))
  // 获取3D零件渲染 dfm 价格计算系数
  inject('get3DInfo', data =>
    app.$axios.get('/65e96920123a8', { params: data })
  )
  // 获取3D零件全部信息
  inject('getAll3DInfo', ({ data, cacheKey }) =>
    app.$axios.get('/64117a143cf4e', { params: data, cancel: true, cacheKey })
  )
  // 获取3D零件DMF渲染信息
  inject('getDfmRendering', data =>
    app.$axios.get('/63fef5ecba25e', { params: data })
  )
  // 重设3D零件模型参数
  inject('put3DParameter', data => app.$axios.post('/6228790d16cc3', data))
  // 删除订单
  inject('delOrder', data => app.$axios.post('/6232cf1aae7ed', data))
  // 重新上传订单PO单
  inject('againUploadPo', data => app.$axios.post('/627103a036eb8', data))
  // 发送验证邮箱邮件
  inject('verifyMail', data =>
    app.$axios.get('/6285a34738b45', { params: data })
  )
  // 用户自行验证邮箱
  inject('verifyMailUrl', data => app.$axios.post('/6284b58036ce5', data))
  // 添加无法自动报价零件记录
  inject('collectNotQuoteRecord', data =>
    app.$axios.post('/63859b0a9c8ab', data)
  )
  //修改询价单名称
  inject('editQuoteNo', data => app.$axios.post('/63806556211a3', data))
  //移动零件至指定询价单
  inject('movePartTo', data => app.$axios.post('/6385c832b31bb', data))
  //可移动询价单列表
  inject('canMoveToQuoteNo', data =>
    app.$axios.get('/6385bfb82c960', { params: data })
  )
  //确认报价
  inject('confirmQuote', data => app.$axios.post('/6384527e84bbf', data))
  // 设置谷歌运营数据
  inject('setOperateData', data => app.$axios.post('/63aaa89fdcdd8', data))
  // 获取谷歌运营数据
  inject('getOperateData', data =>
    app.$axios.get('/63aaa9048c430', { params: data })
  )
  // 审核零件DFM
  inject('approveDfm', data => app.$axios.post('/63ad2dd28a01e', data))
  // 更新零件图纸
  inject('approveUpdateDfm', data => app.$axios.post('/63ad3f426da02', data))
  // 请求人工审查
  inject('requestManualReview', data => app.$axios.post('/63ad45d656b70', data))
  // 批量报价
  inject('batchQuote', data => app.$axios.post('/638842640878c', data))
  //获取交货日期
  inject('getShipByDate', data =>
    app.$axios.get('/63e1c8699e5e7', { params: data })
  )
  //打包下载询价单业务上传的DFM文件
  inject('dowloadDFMRport', data =>
    app.$axios.get('/64097ee2d10bf', { params: data, responseType: 'blob' })
  )
  //获取3D打印自动报价
  inject('file3DQuote', data =>
    app.$axios.get('/6440d516a926c', { params: data })
  )
  //获取零件是否适合钣金
  inject('fileFitMetal', data =>
    app.$axios.get('/64c9ef199dce6', { params: data })
  )
  //
  inject('getPayPalClientToken', data =>
    app.$axios.get('/64647ed68db5d', { params: data })
  )

  inject('payPalCreateOrder', data => app.$axios.post('/6464896a3ae5b', data))
  // 获取PayPal订单详情
  inject('payPalGetOrder', data => app.$axios.get('/6629fdf496d02', { params: data }))
  inject('payPalCapture', data => app.$axios.post('/646489940a984', data))
  //创建团队
  inject('createTeamSubmit', data => app.$axios.post('/64e2c8b9ed2f2', data))
  //团队列表
  inject('getCreateTeamList', data =>
    app.$axios.get('/64e2c89c2a6c3', { params: data })
  )
  //加入团队
  inject('joinCreateTeam', data => app.$axios.post('/64e4111756fc5', data))
  //删除团队
  inject('deleteCreateTeam', data => app.$axios.post('/64e2c90f48ec8', data))
  //转移团队
  inject('transferCreateTeam', data => app.$axios.post('/64ed86a4429c9', data))
  //退出团队
  inject('leaveCreateTeam', data => app.$axios.post('/64ec4d1518a77', data))
  //移除团队成员
  inject('transferTeamMember', data => app.$axios.post('/64ec4ced9bc9d', data))
  //邀请团队成员
  inject('inviteCreateTeam', data => app.$axios.post('/64e410b32e24b', data))
  //编辑团队
  inject('editTeamName', data => app.$axios.post('/64e2faecda4f2', data))
  //获取团队详情
  inject('getTeamDetails', data =>
    app.$axios.get('/64e2fc9078730', { params: data })
  )
  //查询团队名称是否被使用
  inject('isTeamName', data =>
    app.$axios.get('/64e80d46976ec', { params: data })
  )
  //添加询价到团队中
  inject('addQuoteToTeam', data => app.$axios.post('/64edb182659d8', data))
  //将询价单从团队中移除
  inject('removeQuoteToTeam', data => app.$axios.post('/64edba3d1aaaa', data))
  //添加订单到团队中
  inject('addOrderToTeam', data => app.$axios.post('/64edcde1ca0d5', data))
  //将订单从团队中移除
  inject('removeOrderToTeam', data => app.$axios.post('/64edce03cfc07', data))
  //根据邀请码获取团队数据
  inject('getInvitationCodeData', data =>
    app.$axios.get('/64f83a9d12fdc', { params: data })
  )

  //获取自动报价额外交期
  inject('getAutoQuoteoutDelivery', data =>
    app.$axios.post('/653614aa39077', data)
  )
  // 批量下载po单
  inject('poFileDownloadAll', data =>
    app.$axios.get('/657017c8798cc', { params: data, responseType: 'blob' })
  )
  //询价详情DFM确认
  inject('dfmConfirm', data => app.$axios.post('/657a6f5b187ca', data))
  //获取首页文章列表
  inject('getArticleList', data =>
    app.$axios.get('/65894fefa3213', { params: data })
  )
  //获取首页文章详情
  inject('getArticleDetails', data =>
    app.$axios.get('/6589526b2878b', { params: data })
  )

  //询价详情DFM确认
  inject('saveGuideStep', data => app.$axios.post('/659f57490b34d', data))
  // 获取光洁度列表
  inject('getRaFinish', data =>
    app.$axios.get('/66029fa39da36', { params: data })
  )
  // 用户模具列表
  inject('userMoldsList', data => app.$axios.post('/66865f3474470', data))

  // 保存用户操作询价单日志
  inject('saveOperateLog', data => app.$axios.post('/66c2de0806760', data))
}
